<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <Card v-bind:title="'Edit Stok Obat'">
          <template v-slot:body v-if="dataLoaded">
            <Form :form="form" :route="'medicine-stocks/' + form.id" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from "@/component/medicine/MaintainStockFormEdit.vue";
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
       medicine_id: null,
        medicine_name: "",
        stock_in_out: 0,
        quantity: 0,
        remaining_stock: null,
        price: null,
        display_price:null,
        vendor_id: null,
        vendor_name: "",
        batch: "",
        notes: "",
        batch: "",
        hna: 0,
        ppn: 10,
        discount: 0,
        ref_no: "",
        source: "Klinik",
        expired: new Date().toISOString().substr(0, 10),
        notes: "",
      },
      old_medicine: null,
      old_vendor: null,
      // Other
      dataLoaded: false
    }
  },

  methods: {

    async get() {
      this.form = await module.get('medicine-stocks/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/medicine/maintain-stock')
      } else {
        this.form['_method'] = 'put'
         this.form.display_price = parseInt(this.form.price).toLocaleString('id-ID')
        this.form.price = 0
        
        this.dataLoaded = true
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Obat", route: "/medicine/list" },
      { title: "Stok Obat", route: "/medicine/maintain-stock" },
      { title: "Edit" },
    ])
    // Get Data
    this.get()
  },

}

</script>